<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import complex from '../../../../compoment/complex/complex';

formCreate.component('complex', complex);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('channel-cost-config-form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'productCode') {
        rowData.restful = '/mdm/mdmProductController/productSelectList';
        rowData.optionsKey = {
          value: 'productCode',
          label: 'productName',
        };
        rowData.on = {
          ...rowData.on,
          getItem: (value) => {
            this.setValue({ productName: value.productName });
          },
        };
      } return rowData;
    },

    formComplete() {
      if (this.formConfig.id) {
        this.getData(this.formConfig.id);
      }
    },

    async getData(id) {
      const { result } = await this.$http.post(
        '/dms/dms/channelFeeConfig/query', { id },
      );
      result.effective = {
        feeCreateDate: result.feeCreateDate,
        delayDay: result.delayDay,
        unit: result.unit,
      };
      console.log(result);
      this.setValue({
        ...result,
      });
    },

    submit() {
      let params = this.getFormData();
      let path = '/dms/dms/channelFeeConfig/save';
      if (this.formConfig.id) {
        path = '/dms/dms/channelFeeConfig/update';
        params.id = this.formConfig.id;
      }
      params = { ...params, ...params.effective };
      request.post(path, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },
  },
};
</script>
